<template>
  <div>

    <v-card class="corner-ribbon-container my-3" tile v-bind:class="{'highlighted': highlight === true}">
      
      <!-- Overlay of the archived (done / cancelled) and corner ribbon-->
      <div class="card-overlay"
        v-if="['new', 'accepted', 'lala_assigning_driver', 'lala_on_going', 'lala_picked_up'].indexOf(order.status) < 0"
        v-bind:class="{'transition d-none': expansion.cart.show === true}"
        @click="onSelected">
      </div>
      <div>
        <div class="corner-ribbon top-right green" v-if="order.status === 'done'"
          v-bind:class="{'transition': expansion.cart.show === true}">
          已完成
        </div>
        <div class="corner-ribbon top-right green" v-else-if="order.status === 'lala_completed'"
          v-bind:class="{'transition': expansion.cart.show === true}">
          物流完成
        </div>
        <div class="corner-ribbon top-right red" v-else-if="['rejected', 'cancelled'].indexOf(order.status) >= 0"
          v-bind:class="{'transition': expansion.cart.show === true}">
          已取消
        </div>
        <div class="corner-ribbon top-right red" v-else-if="order.status === 'lala_cancelled'"
          v-bind:class="{'transition': expansion.cart.show === true}">
          物流取消
        </div>
        <!-- TODO: More status display -->
      </div>

      <!-- Heading -->
      <div class="px-3 py-4 d-flex align-center justify-space-between"
        @click="onSelected">

        <div class="d-flex align-center">
          <OrderNumberTile :number="order.order_number"
            :type="order.meal_type"
            :status="order.status"
            :reminder="reminder">
          </OrderNumberTile>

          <div class="ml-4 d-inline-flex flex-column">

            <!-- <v-badge left color="orange" class="blink" offset-y="16" offset-x="180"
              :value="expirationTimeLeft <= 10 && !hasExpired"
              :content="`約${expirationTimeLeft}分後逾期`"> -->
              <div>
                <div v-if="order.meal_type != 'delivery'">
                  <span class="text-h6">{{ order.take_meal_time | timeDisplay }}</span>
                  <span class="orange--text text-subtitle-2" v-if="expirationTimeLeft <= 10 && !hasExpired">
                    {{ expirationTimeLeft }}分後逾期
                  </span>
                  <span class="red--text text-subtitle-2" v-if="hasExpired">
                    已逾期
                  </span>
                </div>
                <div v-else>
                  <span class="text-subtitle-1 font-weight-bold" v-if="order.lala_take_meal_time">物流取件 {{ order.lala_take_meal_time | timeDisplay }}<br></span>
                  <span class="text-subtitle-1 font-weight-bold">客人取餐 {{ order.take_meal_time | timeDisplay }}</span>
                  <span class="orange--text text-subtitle-2" v-if="expirationTimeLeft <= 10 && !hasExpired">
                    <br>{{ expirationTimeLeft }}分後逾期
                  </span>
                  <span class="red--text text-subtitle-2" v-if="hasExpired">
                    <br>已逾期
                  </span>
                </div>
                
              </div>
            <!-- </v-badge> -->

            <!-- <span class="body-2">{{ order.take_meal_time | datePart }}</span> -->
            <span class="text-body-1 d-flex align-center">
              ${{ order.amount }}
              <v-chip class="ma-1 pa-1" small outlined v-for="chip in chips" v-bind:key="chip.color"
                :color="chip.color" text-color="black">
                {{ chip.text }}
              </v-chip>
            </span>
          </div>
        </div>
        
      </div>

      <!-- Expansion - Order detail -->
      <v-expand-transition>

        <OrderDetail :order="order" v-if="expansion.cart.show">
        </OrderDetail>
        
      </v-expand-transition>
      
    </v-card>

  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import OrderNumberTile from '../components/OrderNumberTile';
import OrderControl from '../components/OrderControl';
import OrderDetail from '../components/OrderDetail';

export default {
  name: 'OrderCard',
  components: {
    OrderNumberTile,
    // OrderControl,
    OrderDetail
  },
  props: {
    order: Object,
    expandable: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    console.log(`OrderCard mounted: order ID ${this.order.id}, status ${this.order.status}`, this.order);
    //NOTE: Whenever mounted, force a check on reminder status.
    this.checkReminder(moment().unix());
  },

  methods: {
    fetchOrderData() {
      return this.$store.dispatch('fetchOrderCart', { orderId: this.order.id })
      .then(data => {
        console.log(`Order data obtained`, data);
        this.cart = data.cart;
        this.user = data.user;
        this.addon = data.account_addon;
        this.detail = data.order;

        //FIXME: Amend user data.
        this.user.delivery_address = data.order.delivery_address;
        this.user.delivery_time = data.order.delivery_time;
        this.user.delivery_fee = data.order.delivery_fee;
      })
      .catch(err => {
        console.error(`Failed to fetch order data`, err);
      })
    },

    onSelected() {//On clicked, tapped etc.
      if (['new', 'accepted'].indexOf(this.order.status) >= 0 && !this.order.read) {
        this.$store.dispatch('markRead', { orderId: this.order.id })
        .then(() => {});
      }

      //NOTE: If set to not-expandable, it won't expand at all.
      this.expansion.cart.show = this.expandable && !this.expansion.cart.show;

      // If set to not-expandable, we should emit this selection event.
      this.$emit('on-selected', this.order.id);
    },

    /// Expand / collapse utilites
    toggleExpansion(expand) {
      this.expansion.cart.show = expand || !this.expansion.cart.show;
    },
    collapseIfNotFocus(_focusedOrderId) {
      if (_focusedOrderId == this.order.id) return;
      this.expansion.cart.show = false;
    },

    /// Reminder routine
    checkReminder(_currentTs) {
      if (['new', 'accepted'].indexOf(this.order.status) < 0) {
        this.reminder = { show: false };
        return;
      }

      // See if the order has expired. Adjust UI to remind.
      let diffSec = this.order.take_meal_time - _currentTs;
      if (diffSec < 0) {//NOTE: Take-meal-time expired.
        this.hasExpired = true;
        this.expirationTimeLeft = 0;
      }
      else if (diffSec < 600) {//NOTE: 10-min in advance notice.
        // this.reminder = { show: true, color: 'deep-orange darken-1', blink: true, label: `約${Math.ceil(diffSec / 60)}分後逾時` };
        //TODO: Remind about the imminent expiration.
        this.expirationTimeLeft = Math.ceil(diffSec / 60);
      }
      else {
        this.expirationTimeLeft = 11;
      }

      // Determine whether to show non-accepted new orders or unread orders.
      if (this.order.status == 'new') {//Remind about non-accepted new-order.
        this.reminder = { show: true, color: 'green', blink: true, label: '新進單' };
      }
      else if (this.order.read == false) {//Remind about unread.
        this.reminder = { show: true, color: 'green', label: ' ' };
      }
      else {
        this.reminder = { show: false };
      }
    }
  },

  data() {
    return {
      /// Order related data.
      detail: {},
      cart: [],
      addon: {},
      user: {},

      /// Periodic reminder and tags
      reminder: {
        show: false, color: 'white', label: ' '
      },
      hasExpired: false,
      expirationTimeLeft: 11,

      /// Expansions
      expansion: {
        cart: {
          show: false,
          isLoading: false,
          isDataLoaded: false
        }
      },

      /// Overlays
      overlay: {
        newOrder: {
          show: this.order.isFirstBatch !== true && ['new', 'accepted'].indexOf(this.order.status) >= 0
        }
      }
    };
  },

  computed: {
    mealTypeColor() {
      return {
        'eat_in': 'blue',
        'to_go': 'orange',
        'delivery': 'pink lighten-2'
      }[this.detail.meal_types];
    },

    mealTypeLiteral() {
      return {
        'eat_in': '內用',
        'to_go': '外帶',
        'delivery': '外送'
      }[this.detail.meal_types];
    },

    isToday() {
      return this.order.take_meal_time < moment().add(1, 'days').startOf('day').unix();
    },

    chips() {
      let ret = [];

      // Pay method
      let cashLiteral = this.$store.getters.isRetail? '現場／貨到付款': '現場付款';
      ret.push({
        color: {
          'cash': 'red', 'qrpay_apple': 'grey', 'qrpay_google': 'amber',
          'qrpay_line': 'green', 'qrpay_credit': 'cyan'
        }[this.order.payment_type],
        text: {
          'cash': cashLiteral, 'qrpay_apple': 'Apple Pay', 'qrpay_google': 'Google Pay',
          'qrpay_line': 'LINE Pay', 'qrpay_credit': '信用卡'
        }[this.order.payment_type]
      });

      // Table number.
      if (this.order.meal_type == 'eat_in' && this.order.table_number) {
        if (this.order.table_number != 'None')
          ret.push({ color: 'blue', text: `桌 ${this.order.table_number}` });
        else
          ret.push({ color: 'blue', text: `無桌號` });
      }

      // (Delivery only) Lalamove status.
      if (this.order.meal_type === 'delivery') {
        let temp = { color: 'pink darken-4' };
        switch (this.order.status) {
          case 'lala_assigning_driver':
            temp['text'] = '物流媒合中⋯';
            break;
          case 'lala_on_going':
            temp['text'] = '等待取貨中⋯';
            break;
          case 'lala_picked_up':
            temp['text'] = '物流已取貨';
            break;
          case 'lala_completed':
            temp['text'] = '物流已送達';
            break;
          // case 'lala_cancelled':
          //   temp['text'] = '物流取消';
          //   break;
          default:
            temp = undefined;
        }
        if (temp) ret.push(temp);
      }

      return ret;
    }
  },

  watch: {
    'order.status': {
      handler(s) {
        console.warn(`Status of order ${this.id} has changed to ${s}.`);

        // Update the 'badge' of order-number-tile.
        this.checkReminder(moment().unix());

        // Collapse the cart expansion if the order is resolved.
        if (['done', 'cancelled', 'rejected'].indexOf(s) >= 0) {
          this.expansion.cart.show = false;
        }
      }
    },
    'order.read': {
      handler(s) {
        // Update the 'badge' of order-number-tile. Update unread-status.
        this.checkReminder(moment().unix());
      }
    },

    'expansion.cart.show': {//So that we know when to load cart data.
      handler(show) {
        // When expanded...
        if (show === true) {
          this.$emit('on-expanded', this.order.id);//Emit event of expansion opening.

          // // If cart data hasn't yet loaded, request it.
          // if (!this.expansion.cart.isDataLoaded) {
          //   this.expansion.cart.isLoading = true;
          //   this.fetchOrderData()
          //   .then(() => {
          //     this.expansion.cart.isDataLoaded = true;
          //     this.expansion.cart.isLoading = false;
          //   });
          // }
        }

        // When collapsed...
        else {
          this.$emit('on-collapsed', this.order.id);//Emit event of expansion collapsing.
        }
      }
    }
  },

  filters: {
    
    timeDisplay: ts => {
      let m = moment.unix(ts);
      if (ts >= moment().startOf('day').unix() && ts <= moment().endOf('day').unix())
        return m.format('今日 h:mmA');
      let weekDayMandarin = ['日','一','二','三','四','五','六'][m.day()];
      return m.format(`M/D ${weekDayMandarin} h:mmA`);
    },
    // toTwnPhoneString: phone => {
    //   if (phone.indexOf('+886') === 0)
    //     return '0' + phone.substring(4);
    //   if (phone.indexOf('886') === 0)
    //     return '0' + phone.substring(3);
    //   return phone;
    // },

    toLiteral: v => {
      return {
        'cash': '現場', 'qrpay_apple': 'Apple Pay', 'qrpay_google': 'Google Pay',
        'qrpay_line': 'LINE Pay', 'qrpay_credit': '信用卡',
        'eat_in': '內用', 'to_go': '外帶', 'delivery': '外送'
      }[v];
    }
  }
}
</script>

<style scoped>

.highlighted {
  background: #B3E5FC99;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 1;

  -webkit-transition: background-color 1s linear;
  -moz-transition: background-color 1s linear;
  -o-transition: background-color 1s linear;
  transition: background-color 1s linear;
}
.card-overlay.transition {
  background-color: transparent;
}

.new-order-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,128,0,0.3);
  z-index: 1;
}

.corner-ribbon{
  width: 150px;
  background: #e43;
  position: absolute;
  top: 10px;
  left: -40px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  font-weight: bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  z-index: 2;

  -webkit-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  transition: opacity 1s linear;
}
.corner-ribbon.transition {
  opacity: 0.3;
}

/* .corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
} */

.corner-ribbon-container {
  position: relative;
  overflow: hidden;
}

.corner-ribbon.top-left{
  top: 10px;
  left: -40px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 10px;
  right: -40px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 10px;
  left: -40px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -40px;
  bottom: 10px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* .corner-ribbon.white{background: #f0f0f0; color: #555;}
.corner-ribbon.black{background: #333;}
.corner-ribbon.grey{background: #999;}
.corner-ribbon.blue{background: #39d;} */
.corner-ribbon.green{background: #2c7;}
/* .corner-ribbon.turquoise{background: #1b9;}
.corner-ribbon.purple{background: #95b;} */
.corner-ribbon.red{background: #e43;}
/* .corner-ribbon.orange{background: #e82;}
.corner-ribbon.yellow{background: #ec0;} */

/* In/out animations */

th, td {
  font-size: large;
}

.blink span.v-badge__badge {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
