<template>
  <div class="mt-8 d-flex justify-space-between">

    <v-speed-dial v-model="show" direction="top" bottom right fixed
      transition="slide-y-reverse-transition">

      <!-- ACTIVATOR BUTTON -->
      <template v-slot:activator>
        <v-btn fab bottom right v-model="show" :color="themeColorLighten">
          <v-icon v-if="show">mdi-close</v-icon>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <!-- SETTING ITEMS -->
      <v-btn fab small color="red lighten-4" @click="dialog.signout.show = true">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn fab small color="indigo lighten-4" @click="dialog.sound.show = true">
        <v-icon>mdi-bell-ring-outline</v-icon>
      </v-btn>
      <v-btn fab small color="purple lighten-4" @click="dialog.sorting.show = true">
        <v-icon>mdi-sort</v-icon>
      </v-btn>
      <v-btn fab small color="green lighten-4" @click="dialog.search.show = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

    </v-speed-dial>

    <!-- DIALOG - SEARCH -->
    <v-dialog v-model="dialog.search.show" width="320px">
      <v-card>
        <v-card-title class="title">搜尋{{ isRetail? '取件': '取餐' }}單號或手機號碼</v-card-title>
        <div class="px-4">
          <Numpad ref="numpad-search" @submit="onSearchConfirmed"></Numpad>
        </div>
      </v-card>
    </v-dialog>

    <!-- DIALOG - SORTING -->
    <v-dialog v-model="dialog.sorting.show" width="320px">
      <v-card>
        <v-card-title class="headline">排序方法</v-card-title>

        <div class="px-4">
          <v-radio-group v-model="dialog.sorting.mode">
            <v-radio v-for="mode in dialog.sorting.modes" :key="mode.label"
              :label="mode.label" :value="mode.val">
              <template v-slot:label>
                <div>
                  <span class="body-1 black--text">{{ mode.label }}</span><br>
                  <span class="body-2">{{ mode.desc }}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="onSortModeConfirmed">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG - SOUND SETTING -->
    <v-dialog v-model="dialog.sound.show" width="320px">
      <v-card>
        <v-card-title class="headline">提示音效設置</v-card-title>

        <div class="px-4">
          <v-switch v-model="dialog.sound.soundOn"
            :label="`音效已${dialog.sound.soundOn? '開啟': '關閉'}`">
          </v-switch>

          <v-select dense outlined v-model="dialog.sound.incomingSoundSelected"
            label="新進單提醒" :disabled="!dialog.sound.soundOn"
            :items="dialog.sound.incomingSoundOptions">
          </v-select>
          <v-select dense outlined v-model="dialog.sound.pendingSoundSelected"
            label="未接單提醒（手動接單模式限定）" :disabled="!dialog.sound.soundOn"
            :items="dialog.sound.pendingSoundOptions">
          </v-select>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="onSoundSettingConfirmed">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG - SIGNOUT -->
    <v-dialog v-model="dialog.signout.show" width="320px">
      <v-card>
        <v-card-title class="headline">登出</v-card-title>
        <v-card-text>
          您即將登出並離開接單介面，確定嗎？
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog.signout.show = false">否</v-btn>
          <v-btn color="error" text @click="trySignout">是</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Numpad from './Numpad';

export default {
  components: {
    Numpad
  },
  props: {
    signout: Function
  },

  mounted() {

  },

  methods: {
    onSearchConfirmed(v) {
      this.dialog.search.show = false;
      this.$emit('search', v);

      this.$refs['numpad-search'].reset();
    },

    onSortModeConfirmed() {
      this.dialog.sorting.show = false;
      // this.$emit('sort-change', this.dialog.sorting.mode);
      this.$store.commit('setSortMode', this.dialog.sorting.mode);
    },

    onSoundSettingConfirmed() {
      console.warn(`onSoundSettingConfirmed`, this.dialog.sound);
      this.dialog.sound.show = false;
      this.$store.commit('setSoundSetting', {
        enabled: this.dialog.sound.soundOn === true,
        incoming: this.dialog.sound.incomingSoundSelected,
        pending: this.dialog.sound.pendingSoundSelected
      });
    },

    trySignout() {
      this.dialog.signout.show = false;
      this.signout();//This function comes from the parent component.
    }
  },

  data() {
    return {
      show: false,

      dialog: {
        search: {
          show: false,
          searchString: ''
        },
        sorting: {
          show: false,
          mode: 'time',
          modes: [
            { label: '依訂單狀態', desc: '待處理優先、完成／取消最後。其次依時間排序。', val: 'status' },
            { label: `依時間`, desc: '最近到最晚', val: 'time' },
            { label: '依單號', desc: '最小到最大', val: 'number' }
          ]
        },
        sound: {
          show: false,
          soundOn: this.$store.getters.soundSetting.enabled,
          incomingSoundSelected: this.$store.getters.soundSetting.incoming,
          pendingSoundSelected: this.$store.getters.soundSetting.pending,
          incomingSoundOptions: [
            { text: '鋼琴音', value: 'piano' },
            { text: '叮咚', value: 'basic' }
          ],
          pendingSoundOptions: [
            { text: '鋼琴音', value: 'piano' },
            { text: '搖鈴', value: 'basic' }
          ],
        },
        signout: {
          show: false
        }
      }
    };
  },

  computed: {
    isRetail() {
      return this.$store.getters.isRetail;
    },

    themeColorLighten() {
      return process.env.VUE_APP_PWA_THEME_COLOR_LIGHTEN;
    }
  }
}
</script>

<style scoped>
.v-speed-dial--bottom {
  bottom: 48px !important;
}
</style>