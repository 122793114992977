<template>
  <div>

    <v-skeleton-loader class="px-6" v-if="isLoading"
      type="article, actions, table-row, table-row">
    </v-skeleton-loader>

    <div v-else>

      <!-- ORDER BASIC INFO (ONLY SHOWN IN TABLET MODE) -->
      <div class="pa-4" v-if="uiMode === 't'">
        <p class="display-1 text--primary font-weight-black">
          {{ mealTypeLiteral }} {{ order.order_number }}
        </p>
        <div class="text--primary">
          <div v-if="order.meal_type != 'delivery'">
            <span class="title">{{ order.take_meal_time | timeDisplay }}</span>
          </div>
          <div v-else>
            <span class="title" v-if="order.lala_take_meal_time">物流取件 {{ order.lala_take_meal_time | timeDisplay }}</span><br>
            <span class="title">客人取餐 {{ order.take_meal_time | timeDisplay }}</span>
          </div>
        </div>
      </div>

      <!-- Actions -->
      <OrderControl :order-id="detail.order_id" :status="order.status" v-if="uiMode === 't'"
        :order-number="detail.order_number" :type="detail.meal_types"
        :user="user">
      </OrderControl>

      <!-- Table of cart items -->
      <v-simple-table class="px-2">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-h6">品項／加購項</th>
              <th class="text-right text-h6">份</th>
              <th class="text-right text-h6">小計</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in cart" :key="idx">
              <td>
                <span class="text-subtitle-1 text-md-h5">{{ item.product_name }}</span>
                <span class="grey--text text-subtitle-2 text-md-subtitle-1" v-if="item.display && item.display.length"><br>{{ item.display }}</span>
              </td>
              <td class="text-right text-h6 text-md-h5">{{ item.qty }}</td>
              <td class="text-right text-h6 text-md-h5">${{ (item.product_amount + item.addon_amount) * item.qty }}</td>
            </tr>
            <!-- Extra -->
            <tr v-if="addon" style="background: #efefef">
              <td class="font-weight-bold text-subtitle-1 text-md-h5">{{ addon.display }}</td>
              <td class="text-right text-h6 text-md-h5"></td>
                <td class="text-right text-h6 text-md-h5">${{ addon.amount }}</td>
              </tr>
              <!-- Extra fee for delivery -->
              <tr v-if="order.meal_type == 'delivery'" style="background: #ffcfcf">
                <td class="font-weight-bold text-subtitle-1 text-md-h5">運費</td>
                <td class="text-right text-h6 text-md-h5"></td>
                <td class="text-right text-h6 text-md-h5">${{ detail.delivery_fee }}</td>
              </tr>
              <tr v-if="order.meal_type == 'delivery' && detail.shop_covered_fee > 0" style="background: #ffcfcf">
                <td class="font-weight-bold text-subtitle-1 text-md-h5">店家負擔</td>
                <td class="text-right text-h6 text-md-h5"></td>
                <td class="text-right text-h6 text-md-h5">-${{ detail.shop_covered_fee }}</td>
              </tr>
              <!-- Extra discount -->
              <tr v-if="detail.discount > 0" style="background: #fcffb8">
                <td class="font-weight-bold text-subtitle-1 text-md-h5">一般折扣</td>
                <td class="text-right text-h6 text-md-h5"></td>
                <td class="text-right text-h6 text-md-h5">-${{ detail.discount }}</td>
              </tr>
              <tr v-if="detail.qc_money > 0" style="background: #ffdfb8">
                <td class="font-weight-bold text-subtitle-1 text-md-h5">快金折扣</td>
                <td class="text-right text-h6 text-md-h5"></td>
                <td class="text-right text-h6 text-md-h5">-${{ detail.qc_money }}</td>
              </tr>

            </tbody>
          </template>
        </v-simple-table>

        <!-- Additional notes -->
        <v-sheet class="ma-1 mt-2 px-4 text-body-1 text-md-h5" color="grey lighten-2" v-if="detail.notes && detail.notes.length">
          {{ detail.notes }}
        </v-sheet>

        <OrderControl :order-id="detail.order_id" :status="order.status" v-if="uiMode === 'm'"
          :order-number="detail.order_number" :type="detail.meal_types"
          :user="user">
        </OrderControl>

    </div>

    

  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import OrderControl from '../components/OrderControl';

export default {
  name: 'OrderDetail',
  components: {
    OrderControl
  },
  props: {
    order: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  mounted() {
    console.log(`OrderDetail mounted: order ID ${this.order.id}(${this.order.order_id}), status ${this.order.status}`, this.order);
    // this.fetchOrderData();
  },

  methods: {
    fetchOrderData() {
      this.isLoading = true;

      let orderId = this.order.order_id || `${this.order.id}`.padStart(10, '0');

      return this.$store.dispatch('fetchOrderCart', { orderId })
      .then(data => {
        console.log(`Order data obtained`, data);
        this.cart = data.cart;
        this.user = data.user;
        this.addon = data.account_addon;
        this.detail = data.order;

        //FIXME: Amend user data.
        this.user.delivery_address = data.order.delivery_address;
        this.user.delivery_time = data.order.delivery_time;
        this.user.delivery_fee = data.order.delivery_fee;
        this.user.recipient = {
          name: data.order['reciver'], phone: data.order['reciver_phone'] //NOTE: These are indeed typos and WAS NOT MY FAULT!!
        };
      })
      .catch(err => {
        console.error(`Failed to fetch order data`, err);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },

  data() {
    return {
      /// Order related data.
      detail: {},
      cart: [],
      addon: {},
      user: {},

      /// Flags
      isLoading: false
    };
  },

  computed: {
    controlList() {
      if (!this.detail) return [];
      return [
        {
          orderId: this.detail.order_id,
          status: this.order.status,
          orderNumber: this.detail.order_number,
          type: this.detail.meal_types
        }
      ];
    },

    uiMode() {
      return this.$store.getters.uiMode || 'm';
    },

    mealTypeLiteral() {
      let mealTypeLabels = this.$store.getters.mealTypeLabels;
      return {
        'eat_in': mealTypeLabels[0],
        'to_go': mealTypeLabels[1],
        'delivery': mealTypeLabels[2]
      }[ this.order.meal_type ];
    },

    textClassHeading() {
      let isTablet = (this.$store.getters.uiMode || 'm') === 't';
      return { 'text-h5': isTablet, 'text-h6': !isTablet };
    },
    textClassItemTitle() {
      let isTablet = (this.$store.getters.uiMode || 'm') === 't';
      return { 'text-h5': isTablet, 'text-body-1': !isTablet };
    },
    textClassItemSubtitle() {
      let isTablet = (this.$store.getters.uiMode || 'm') === 't';
      return { 'text-subtitle-1': isTablet, 'text-caption': !isTablet };
    },
  },

  watch: {
    'order.id': {
      immediate: true,
      handler(_id) {
        console.warn(`[DEBUG] OrderDetail: order.id has changed to`, _id);
        if (_id)
          this.fetchOrderData();
      }
    }
  },

  filters: {
    // mealTypeLiteral(v) {
    //   return {
    //     'eat_in': '內用',
    //     'to_go': '外帶',
    //     'delivery': '外送'
    //   }[v];
    // },

    timeDisplay: ts => {
      let m = moment.unix(ts);
      if (ts < moment().add(1, 'day').startOf('day').unix())
        return m.format('今日 h:mmA');
      let weekDayMandarin = ['日','一','二','三','四','五','六'][m.day()];
      return m.format(`M/D (${weekDayMandarin}) h:mmA`);
    },
  }
}
</script>

<style scoped>

</style>