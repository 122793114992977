<template>
  <div>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar top v-bind="snackbar.attrs"
      v-model="snackbar.show">
      {{ snackbar.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { firestore, FirestoreDataEvent } from '../firebase';
import SoundManager from '../sound-manager';

export default {
  name: 'BasePos',

  components: {

  },

  mounted() {

    // Sync/bind order list with backend (QC-APIS) and Firestore.
    this.$store.dispatch('requestSync')
    .then(sync_orders => {
      return this.$store.dispatch('bindOrders');
    })
    .then(() => {
      // Now we can start Firestore listener.
      this.initFirestoreListener();
      // Emit event about the completion of sync.
      FirestoreDataEvent.$emit('orders-sync-done');
    })
    //WARNING: Force a signout if sync/bind fail. This might indicate an expired login session.
    .catch(err => {
      console.error(`[BasePos] Failed to sync/bind orders.`, err);
      // alert('登入時效逾期，您需要重新登入。');
      // // Request sign-out.
      // this.$store.dispatch('signout')
      // .then(() => {
      //   console.log(`Sign-out succeeded.`);
      // })
      // .catch(err => {
      //   console.error(`Sign-out failed`, err);
      //   alert('Sign-out failed.');
      // })
      // .finally(() => {
      //   setTimeout(() => {
      //     this.$router.push('/auth');
      //   }, 300)
      // });
    });

  },

  methods: {

    initFirestoreListener() {//WARNING: Should only be called after authenticated.
      this.snapshotListenerUnsubscriber = firestore.collection(`shops/${this.shopId}/orders`)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          // Handle incoming new order(s).
          if (change.type === 'added') {
            let order = change.doc.data();
            //TODO: Show a non-dismissable alert about the newly arrived order.

            // Play notification sound once.
            SoundManager.get().play('incoming');

            // Emit this event.
            console.log(`[Firestore Listener] New order added:`, order);
            FirestoreDataEvent.$emit('order-added', change.doc.data());
          }

          // Handle order data modification (usually means status changes).
          else if (change.type === 'modified') {
            let order = change.doc.data();
            
            // Emit this event.
            console.log(`[Firestore Listener] Order ${order.id}'s status changed to ${order.status}.`);
            FirestoreDataEvent.$emit('order-modified', order);
          }
        });
      });

      FirestoreDataEvent.$on('unsubscribe-orders', () => {
        this.unsubscribeFirestoreListener();
      });
    },

    unsubscribeFirestoreListener() {
      if (this.snapshotListenerUnsubscriber)
        this.snapshotListenerUnsubscriber();
    }

  },

  data() {
    return {
      snapshotListenerUnsubscriber: undefined,

      snackbar: {
        show: false,
        msg: '',
        attrs: { }
      },
    };
  },

  computed: {
    shopId() {
      return this.$store.state.shopId;
    },
    // shopName() {
    //   return this.$store.state.shopName;
    // },

    globalNotification() {
      return this.$store.state.notification;
    }
  },

  watch: {
    globalNotification(v) {
      console.warn(`global notification updated`, v);
      this.snackbar.attrs = v.attrs;
      this.snackbar.msg = v.msg;
      this.snackbar.show = true;
    }
  }
}
</script>